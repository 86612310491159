<template>
  <card-background>
    <div class="banner-container">
      <div class="section-wrapper">
        <div class="heading__wrapper">
          <h2 class="heading heading_size_h2">Поиск по классу</h2>
          <!-- <p class="body body_size_M">Выбери класс и предмет</p> -->
        </div>
        <div class="filter__button-wrapper">
          <button
            v-for="grade in data"
            :key="grade.id"
            :class="[
              'button',
              'button_type_filter-classes',
              { [`selected_button`]: activeButtonIndex === grade }
            ]"
            @click="logGrade(grade)"
          >
            <span
              :class="[
                'label',
                windowWidth <= 568 ? 'label_size_S' : 'label_size_M',
                { [`selected`]: activeButtonIndex === grade }
              ]"
            >
              {{ getFirstWord(grade.title) }}
            </span>
          </button>
        </div>
      </div>
      <BannerOriginalSolutions
        v-if="showBanner" 
        class="banner-container__banner"
      />
    </div>
    <hr
      v-if="selectedGrade !== null" 
      class="dropdown__item dropdown__item_type_divider" 
    />
    <div class="section-wrapper" v-if="selectedGrade !== null">
      <div class="filter__classes-wrapper">
        <ul class="filter__list">
          <li
            v-for="(course, index) in regularCourses" 
            :key="course.id" 
            v-show="index < visibleCourses || showAllCourses"
          >
            <router-link
              :to="{
                name: getDestRouteName,
                query: { subject: course.id, level: selectedGrade.id }
              }"
              class="link"
              :class="{ active: activeCourseId === course.id }"
            >
              <button
                :class="[
                  'button',
                  'button_type_filter-subject',
                  { [`selected_button`]: $route.query.subject === String(course.id) && $route.path.includes('course') }
                ]"
                @click="scrollToMainContent(course)"
                ref="scrollButton"
              >
                <span
                  :class="[
                    'label',
                    windowWidth <= 568 ? 'label_size_S' : 'label_size_M',
                    { [`selected`]: $route.query.subject === String(course.id) && $route.path.includes('course') }
                  ]"
                >
                  {{ course.title }}
                </span>
              </button>
            </router-link>
          </li>
          <button
            :class="['button', 'button_type_filter-subject']"
            @click="toggleShowAllCourses"
          >
            <span
              :class="['label', windowWidth <= 568 ? 'label_size_S' : 'label_size_M',]"
            >
              {{ showAllCourses ? 'Скрыть все предметы' : `Еще ${calculateCourses()} ${calculateEnding()}` }}
            </span>
            <svg-icon class="button__icon button_icon_left" :iconName=" showAllCourses ? 'kuiIconChevronUp' : 'kuiIconChevronDown'" :size="'16px'"/>
          </button>
        </ul>
      </div>

      <div class="section-wrapper_type_additional" v-if="compositionCourse">
        <h3 class="heading heading_size_h3">Дополнительные материалы</h3>
        <ul class="filter__list">
          <li :key="compositionCourse.id">
            <router-link
              :to="{
                name: getDestRouteName,
                query: { subject: compositionCourse.id, level: selectedGrade.id }
              }"
              class="link"
              :class="{ active: activeCourseId === compositionCourse.id }"
            >
              <button
                :class="[
                  'button',
                  'button_type_filter-subject',
                  { [`selected_button`]: $route.query.subject === String(compositionCourse.id) && $route.path.includes('course') }
                ]"
                @click="scrollToMainContent(compositionCourse)"
                ref="scrollButton"
              >
                <span
                  :class="[
                    'label',
                    windowWidth <= 568 ? 'label_size_S' : 'label_size_M',
                    { [`selected`]: $route.query.subject === String(compositionCourse.id) && $route.path.includes('course') }
                  ]"
                >
                  {{ compositionCourse.title }}
                </span>
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </card-background>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import Button from '@/components/KazUI/atoms/button';
import BannerOriginalSolutions from '@/components/common/BannerOriginalSolutions.vue';

import useEditorIntro from '@/hooks/routes/useEditorIntro.js';
import { coursesComparator } from '@/utils/courses';



export default {
  name: 'subject-class-filter',

  components: {
    CardBackground,
    HeadingSizeH2,
    HeadingSizeH3,
    ParagraphSizeLarge,
    ParagraphSizeMedium,
    Button,
    BannerOriginalSolutions,
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    activeGradeId: {
      type: Number
    },
    activeCourseId: {
      type: Number
    }
  },

  setup() {
    const { getDestRouteName } = useEditorIntro('course');
    return { getDestRouteName }
  },

  data() {
    return {
      selectedGrade: null,
      activeButtonIndex: null,
      selectedCourse: null,
      showAllCourses: false,
      visibleCourses: 5,
      windowWidth: window.innerWidth,
    };
  },

  methods: {
    calculateCourses(){
      return this.regularCourses.length - this.visibleCourses
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    calculateEnding() {
      const coursesCount = this.calculateCourses();
      let ending;

      switch (coursesCount) {
        case 1:
          ending = "предмет";
          break;
        case 2:
        case 3:
        case 4:
          ending = "предмета";
          break;
        default:
          ending = "предметов";
      }
      return ending;
    },
    toggleShowAllCourses() {
      this.showAllCourses = !this.showAllCourses;
    },
    getFirstWord(title) {
      return title.split(' ')[0];
    },
    logGrade(grade) {
      this.selectedGrade = grade;
      this.activeButtonIndex = grade;
    },
    scrollToMainContent(course) {
      this.selectedCourse = course.id;
      let parent = this.$parent;
      while (parent) {
        if (parent.$options.name === 'main-layout') {
          const mainContentElement = parent.$refs.mainContent;
          if (mainContentElement) {
            mainContentElement.scrollIntoView({ behavior: 'smooth' });
          }
          break;
        }
        parent = parent.$parent;
      }
    },
    updateLink() {
      if (!this.selectedGrade) {
        return;
      }
      if (!this.selectedCourse) {
        if (this.getSelectedCourses.length > 0) {
          this.selectedCourse = this.getSelectedCourses[0].id;
        } else {
          return;
        }
      } else {
        const courseExists = this.getSelectedCourses.some(course => course.id === this.selectedCourse);
        if (!courseExists) {
          if (this.getSelectedCourses.length > 0) {
            this.selectedCourse = this.getSelectedCourses[0].id;
          } else {
            return;
          }
        }
      }
      const link = {
        name: this.getDestRouteName,
        query: { subject: this.selectedCourse, level: this.selectedGrade.id },
      };
      this.$router.push(link);
    }
  },

  watch: {
    selectedGrade: 'updateLink',
    selectedCourse: 'updateLink',
  },
  
  computed: {
    getSelectedCourses() {
      return this.selectedGrade 
        ? this.selectedGrade.courses.toSorted(coursesComparator)
        : [];
    },
    regularCourses() {
      return this.getSelectedCourses.filter(
          course => course.id !== 29 || course.title !== "Сочинения"
        );
    },
    compositionCourse() {
      return this.getSelectedCourses.find(
        course => course.id === 29 && course.title === "Сочинения"
      ) || null;
      return this.selectedGrade ? this.selectedGrade.courses.find(course => course.id === 29 && course.title === "Сочинения") : null;
    },
    link() {
      if (this.selectedGrade && this.selectedCourse) {
        return {
          name: this.getDestRouteName,
          query: { subject: this.selectedCourse, level: this.selectedGrade.id },
        };
      }
      return null;
    },
    showBanner() {
      const allowedPageNames = [ 'tasks', 'course', ];
      return allowedPageNames.includes(this.$route.name);
    }
  },
  
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  }
};
</script>


<style scoped>
.section-wrapper{
  display: flex;
  flex-direction: column;
  gap:16px;
  max-width: 100%;
}
.heading__wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.section-wrapper_type_additional{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.filter__classes-wrapper{
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.filter__list{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.link{
  text-decoration: none;
}
.filter__button-wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
.button{
  outline: none;
}
.button_type_filter-classes{
  background-color: var(--kaz-status-neutral-bg);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  transition: .3s ease-in-out;
  flex-shrink: 0;
}

.button_type_filter-classes:hover{
  background-color: var(--kaz-status-neutral-bg-hover);
  transition: .3s ease-in-out;
}

.button_type_filter-subject{
  background-color: var(--kaz-status-neutral-bg);
  border-radius: 8px;
  padding: 12px 16px;
  transition: .3s ease-in-out;
}

.button_type_filter-subject:hover{
  background-color: var(--kaz-status-neutral-bg-hover);
  transition: .3s ease-in-out;
}

.selected{
  color: var(--kaz-textIcons-day-text-01)
}
.selected_button{
  background-color: var(--kaz-base-primary);
}
.selected_button:hover{
  background-color: var(--kaz-base-primary-hover);
}
.button_icon_left{
  --kaz-base-base-09: var(--kaz-status-neutral-fill);
}
.dropdown__item_type_divider{
  margin: 16px 0px;
}

.banner-container {
  display: flex;
  gap: 16px;
}

.banner-container__banner {
  flex: 0 0 50%;
}

@media screen and (max-width: 992px) {
  .filter__button-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .filter__button-wrapper::-webkit-scrollbar {
    display: none;
  }
  .button_type_filter-classes{
    width: 40px;
    height: 40px;
    transition: .3s ease-in-out;
  }
  .dropdown__item_type_divider{
    margin: 8px 0px;
  }
  .button_type_filter-classes{
    width: 36px;
    height: 36px;
  }
  .button_type_filter-subject{
    border-radius: 8px;
    padding: 8px 12px;
  }
  .banner-container {
    flex-direction: column;
  }
  .banner-container__banner {
    order: -1;
  }
}
</style>
