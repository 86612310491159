!<template>
  <article class="main-banner main-banner__back">
    <h2 class="heading heading_size_h2">
      Мы платим за оригинальные решения
    </h2>
    <p class="body body_size_M main-banner__description">
      Заполняй решения для отмеченных красным тэгом заданий и зарабатывай «денежки»
    </p>
    <KazButton
      class="main-banner__link"
      size="M"
      appearance="accent"
      label="Перейти к заполнению"
      @click="openPublicFile('/manual-money.pdf')"
    />
  </article>
</template>

<script>
import KazButton from '@/components/KazUI/atoms/button'

import { openPublicFile } from '@/utils/links.js';


export default {
  name: 'banner-original-solutions',

  components: {
    KazButton,
  },

  methods: {
    openPublicFile,
  }
}
</script>

<style scoped>
.main-banner {
  padding: 24px;
  background: #FFBBBB;
  border-radius: 16px;
  isolation: isolate;
}

.main-banner__back {
  background-image: url('@/assets/KazIllustrations/finance/denezki-logo.png');
  background-repeat: no-repeat;
  background-position: right;
}

.main-banner__link {
  margin-top: 16px;
}

.main-banner__description {
  margin-top: 8px;
  padding-right: 220px;
}

@media screen and (max-width: 576px) {
  .main-banner__back {
    background-image: initial;
  }
  .main-banner__description {
    padding-right: 0;
  }
}
</style>
